/**
 *
 */
export default class RestApi {
  auth = {
    login: (data) => $app.api.post([ 'auth/login', ]).data(data),
    loginPin: (userId, data) => $app.api.post([ 'auth/login/users*/pin', userId, ]).data(data),
    register: (iin, phone, data) => $app.api.post([ 'auth/register**', iin, phone, ]).data(data),
    recover: (iin, data) => $app.api.post([ 'auth/recover*', iin, ]).data(data),
    info: () => 
      $app.api.get([ 'auth/info' ]).config({
        headers: {
          'Cache-version': process.env.VUE_APP_VERSION, // Добавлен заголовок Cache-version
        },
      }),
    checkData: (data) => $app.api.post([ 'auth/check-data', ]).data(data),

    sendCode: (iin, phone) => $app.api.post([ 'sms/send-code**', iin, phone, ]),
    checkCode: (iin, data) => $app.api.post([ 'sms/check-code*', iin, ]).data(data),

    sendCodeOtp: (data) => $app.api.post([ 'sms/otp/send**' ]).data(data),
    checkCodeOtp: (iin, data) => $app.api.post([ 'sms/otp/check*', iin, ]).data(data),

    sendPermanentCodeOtp: (data) => $app.api.post([ 'sms/permanent-otp/send**' ]).data(data),
    checkPermanentCodeOtp: (documentId, data) => $app.api.post([ `sms/permanent-otp/check/${documentId}` ]).data(data),

    deleteAcc: (id) => $app.api.patch([ 'auth/delete/users*', id, ]),
    checkIin: (iin) => $app.api.post([ 'auth/iin-check*', iin, ]),
    refreshToken: (data) => $app.api.post([ 'auth/refresh-token', ]).data(data),
    loginWith2FA: (data) => $app.api.post([ '/auth/login-with-2FA', ]).data(data),
    authByUuid: (uuid) => $app.api.post('auth/by-uuid/' + uuid ),
  }

  lang = {
    locales: (lang) => $app.api.get([ 'locales', ]),
    items: () => $app.api.get([ 'translations/content', ]),
  }

  integration= {
    postFile: (file) => $app.api.post([ '/prosper/users/post-excel', ])
      .data(file)
      .config({
        headers: {
          'Content-type': 'multipart/form-data',
        },
      }),
    history: () => $app.api.get([ '/prosper/integration-files/history', ]),
    getTemplate: () => $app.api.get([ '/prosper/integration-files/template', ]),
  }
  companies = {
    get: (id) => $app.api.get([ 'companies*', id, ]),
    excel: (id, data) => $app.api.post([ 'companies*/excel', id, ]).data(data),
    edit: (id, data) => $app.api.patch([ 'companies*', id, ]).data(data),
    pitech: (id, data) => $app.api.post([ 'companies*/pitech', id, ]).data(data),
    pitechExcel: (id, data) => $app.api.post([ 'companies*/pitech/excel', id, ]).data(data),
    usersExcel: (id) => $app.api.get([ 'companies*/users/excel', id, ]),
    woopayHistory: (id) => $app.api.get([ 'companies*/woopay/history', id, ]),
    getFilials: (id) => $app.api.get([ 'companies*/filials', id, ]),
    getDepartments: (id) => $app.api.get([ 'companies*/departments', id, ]),
  }

  users = {
    needAgreement: (userId, companyId) => $app.api.get([ 'check/need-agreement**', userId, companyId, ]),
    get: (id) => $app.api.get([ 'users*', id, ]),
    getCompany: (id) => $app.api.get([ 'users-companies*', id, ]),
    editCompany: (id) => $app.api.patch([ 'users-companies*', id, ]),
    getMyCompanies: (id) => $app.api.get([ 'users*/companies', id, ]),
    getByCompany: (companyId) => $app.api.get([ 'companies*/users*', companyId, ]).filter({ status: 'not:fired', }),
    edit: (id, data) => $app.api.patch([ 'users*', id, ]).data(data),
    editUserCompany: (id, data) => $app.api.patch([ 'userCompany*', id, ]).data(data),
    editPassword: (id, data) => $app.api.patch([ 'users*/password', id, ]).data(data),
    checkPassword: (id, data) => $app.api.patch([ 'users*/password-check', id, ]).data(data),

    sendCode: (id) => $app.api.post([ 'users*/send-code', id, ]),
    checkCode: (id, data) => $app.api.post([ 'users*/check-code', id, ]).data(data),

    editPin: (id, data) => $app.api.patch([ 'users*/edit-pin', id, ]).data(data),
    editLimit: (id, data) => $app.api.patch([ 'users*/limit', id, ]).data(data),
    editEmail: (data) => $app.api.patch([ 'users*/email', ]).data(data),
    switchOn2FA: (turnOn = true) => $app.api.patch([ `users*/2FA/${turnOn ? 'on' : 'off'}`, ]),
    getIs2Fa: (id) => $app.api.get([ '/users/2FA', id, ]),
    search: (data, request) => $app.api.post([ 'users*/search', request, ]).data(data),

    state: (userId, stepId) => $app.api.post([ `users/${userId}/update-state/${stepId}` ]),
    locale: (userId, lang) => $app.api.patch([ `users/update/${userId}?locale=${lang}` ])
  }

  transactions = {
    check: (companyId, userId, data) => $app.api.post([ 'companies*/users*/transactions/check', companyId, userId, ]).data(data),
    create: (companyId, userId, data) => $app.api.post([ 'companies*/users*/transactions', companyId, userId, ]).data(data),
    getList: (companyId, userId, request = '') => $app.api.get([ 'companies*/users*/transactions' + request, companyId, userId, ]),

    //* Новая логика транзакции
    start: (data) => $app.api.post([ 'process/start' ]).data(data),
    createTransaction: (data) => $app.api.post([ `/v2/company/transaction` ]).data(data),
    step: (stepId) => $app.api.get([ `process/${stepId}/next-step` ]),
    needApprove: (uuid) => $app.api.get([ `transaction/${uuid}/need-approve` ]),
    complete: (data) => $app.api.post([ '/v2/company/transaction/complete' ]).data(data)
  }

  payment = {
    doPay: (userId, data) => $app.api.post([ 'payment/do*', userId, ]).data(data),
    woopayDoPay: (data) => $app.api.post([ 'woopay/transaction', ]).data(data),
    wooopayGetCards: () => $app.api.get([ 'payment/woopay/get-cards', ]),
    berekeDoPay: (data) => $app.api.post([ 'bereke/transaction', ]).data(data),
    berekeGetCards: () => $app.api.get([ 'payment/bereke/get-cards', ]),
    saveCard: (userId) => $app.api.post([ 'payment/save-card', userId, ]),
    getCards: (userId) => $app.api.post([ 'payment/get-cards', userId, ]),
    deleteCard: (cardsId) => $app.api.post([ 'payment/delete-card*', cardsId, ]),
    deleteWoopayCard: (cardsId) => $app.api.post([ 'payment/woopay/delete-card*', cardsId, ]),
    deleteBerekeCard: (cardsId) => $app.api.post([ 'payment/bereke/delete-card*', cardsId, ]),
    
    //* Новая логика транзакции
    getCardsNew: () => $app.api.get([ '/v2/cards' ]),
    deleteCardNew: (cardId) => $app.api.delete([ `/v2/cards/${cardId}` ])
  }

  contacts = {
    get: () => $app.api.get([ 'contacts', ]),
  }

  application = {
    getByUser: (userId) => $app.api.get([ 'applications/users*', userId, ]),
    create: (data) => $app.api.post([ 'applications  ', ]).data(data),
  }
  commission = {
    getMin: () => $app.api.get([ 'commission/minimal', ]),
    calculate: (data) => $app.api.post([ 'commissions/calculate', ]).data(data),
  }
  faqs = {
    get: () => $app.api.get([ 'faqs', ]),
  }

  notifications = {
    get: (userId) => $app.api.get([ 'notifications/users*', userId, ]),
    getUnreadCount: (userId) => $app.api.get([ 'notifications/users*/unread-count', userId, ]),
    delete: (id) => $app.api.delete([ 'notifications*', id, ]),
    patchCategoryNotification: (userId, companyId) => $app.api.patch([ `notifications?userId=${userId}&category=${companyId}`, ]),
    getImage: (id) => $app.api.get([ 'notifications/images', id ]).config({
      responseType: 'blob'
    })
  }

  fcmPush = {
    save: (data) => $app.api.post('fcm-push/save').data(data),
    deleteToken: (data) => $app.api.delete('fcm-push/delete').data(data),
  }
  branches = {
    get: (companyId, id) => $app.api.get([ 'companies*/branches*', companyId, id, ]),
    create: (companyId, data) => $app.api.post([ 'companies*/branches', companyId, ]).data(data),
    edit: (companyId, id, data) => $app.api.patch([ 'companies*/branches*', companyId, id, ]).data(data),
    delete: (companyId, id) => $app.api.delete([ 'companies*/branches*', companyId, id, ]),
  }

  timeTracker = {
    clockIn: (data) => $app.api.post([ 'time-tracker/clock-in', ]).data(data),
    clockOut: (data) => $app.api.post([ 'time-tracker/clock-out', ]).data(data),
    getConfirmations: () => $app.api.get([ 'time-tracker/confirmations', ]),
    getVisits: () => $app.api.get([ 'time-tracker/visits', ]),
    getFilialWorkDays: (filialId) => $app.api.get([ 'time-tracker*/work-days', filialId, ]),
    getUserVisitsForDay: (dayId) => $app.api.get([ 'time-tracker*/visits', dayId, ]),
    getActiveTimeTracker: () => $app.api.get([ 'time-tracker', ]),
  }

  filials = {
    get: (id) => $app.api.get([ 'companies*/filials', id, ]),
    create: (id, data) => $app.api.post([ 'companies*/filials', id, ]).data(data),
    delete: (id) => $app.api.delete([ 'filials*', id, ]),
    getById: (id) => $app.api.get([ 'filials*', id, ]),
    getUsers: (id) => $app.api.get([ 'filials*/users', id, ]),
    getFreeUsers: (id) => $app.api.get([ 'filials*/users/free', id, ]),
    addUsers: (id, data) => $app.api.post([ 'filials*/users', id, ]).data(data),
    removeUser: (id, userId) => $app.api.delete([ 'filials*/users*', id, userId, ]),
    edit: (id, data) => $app.api.put([ 'filials*', id, ]).data(data),
  }

  eds = {
    create: (documentId) => $app.api.post([ 'eds/b-key/create*', documentId, ]),
    check: (uuid) => $app.api.get([ 'eds/verify*', uuid, ]),
    complete: (uuid) => $app.api.get([ 'eds/b-key/complete*', uuid, ]),
    checkWorkTime: () => $app.api.post([ 'eds/check-time', ]),
    updateStatus: () => $app.api.get([ 'eds/b-key/status/update', ]),
  }

  document = {
    generate: (companyId, type, data) => $app.api.post([ 'document/generate**', companyId, type, ]).data(data),
    addSignature: (documentId, data) => $app.api.post([ 'document*', documentId, ]).data(data),
    getDocumentByCompany: (companyId) => $app.api.get([ 'document/company*', companyId, ]),

    getDocumentByCompanyWithoutDepartment: (companyId) => $app.api.get([ 'document/company*/all', companyId, ]),
    getDocumentByUser: (userId) => $app.api.get([ 'document/user*', userId, ]),
    getDocumentByDepartment: (departmentId) => $app.api.get([ 'document/department*', departmentId, ]),
    getDocumentsInfo: (companyId) => $app.api.get([ 'document/company*/info', companyId, ]),
    getDocumentData: (documentId) => $app.api.get([ 'document/status*', documentId, ]),
    getDocumentDto: (documentId) => $app.api.get([ 'document/data*', documentId, ]),
    getDocumentsInfoByDepartment: (departmentId) => $app.api.get([ 'document/department*/info', departmentId, ]),
    getDocumentWithQr: (documentId) => $app.api.get([ 'document/qr*', documentId, ]),
    getDocumentWithQrOtp: (documentId) => $app.api.get([ 'document/otp*', documentId, ]),
    getNcaCms: (documentId) => $app.api.get( [ 'document/cms*', documentId, ] ),
    getCompanyDocumentsFiltered: (companyId, request) => $app.api.get( [ 'document/company*/filter', companyId, request ] ),
    getDepartmentDocumentsFiltered: (departmentId, request) => $app.api.get( [ 'document/department*/filter', departmentId, request ] ),
    getDocumentDataDto: (id) => $app.api.get( [ 'document**/data', id, ] ),
    downloadZip: (companyId, request) => $app.api.get( [ 'document/company*/dataQr/download-zip', companyId, request ] ).config({
      responseType: 'blob'
    }),
  }

  departments = {
    getUsers: (id) => $app.api.get([ 'departments*/users', id, ]),
  }
  postSignature = {
    getAwaitingSum: () => $app.api.get([ 'post-signature/awaiting', ]),
  }

  limits = {
    get: (id) => $app.api.get([ 'limits', id, ]),
    getByCompany: (companyId) => $app.api.get([ 'limits*/all', companyId, ]),
    create: (companyId, data) => $app.api.post([ 'limits*/create', companyId, ]).data(data),
    remove: (id) => $app.api.post([ 'limits/delete*', id, ]),
  }

  analytics = {
    create: (data) => $app.api.post([ 'analytics/click', ]).data(data),
  }

  groupLimits = {
    get: (id) => $app.api.get([ 'limits', id, ]),
    getByCompany: (companyId) => $app.api.get([ 'limits/limit-type*/all', companyId, ]),
    create: (data) => $app.api.post([ 'limits/limit-type*/create', ]).data(data),
    update: (data) => $app.api.patch([ 'limits/limit-type*/update', ]).data(data),
    remove: (id) => $app.api.delete([ 'limits/limit-type/delete*', id, ]),
  }

  accessBlock = {
    get: (userId, companyId) => $app.api.get([ `check/accessBlock?userId=${userId}&companyId=${companyId}`, ]),
  }
}
